/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import axios from 'axios';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import FrigolLandingPageContent from 'components/frigol-content-landing-page';
import PagueMenosLandingPageContent from 'components/pague-menos-content-landing-page';
import {
  Container,
} from './styles';

const LandingPageMgm = () => {
  const { companyMgm } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [isValid, setIsValid] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}landing-pages/path/${companyMgm}`, { headers: { Authorization: `Bearer ${token}` } });

        if (response.status === 200) {
          setPathData(response.data);
          setIsValid(true);
        }
      } catch (error) {
        setIsValid(false);
      }
    };

    checkEndpoint();
  }, [companyMgm]);

  React.useEffect(() => {
    if (isValid === false) {
      history.push('/');
    }
  }, [isValid, history]);

  if (isValid === null) {
    return false;
  }

  if (isValid) {
    return (
      <Container>
        {pathData.path === 'frigol' && (
          <FrigolLandingPageContent
            logoUrl={pathData.logoUrl}
            videoUrl={pathData.videoUrl}
            pageTitle={pathData.pageTitle}
            pageContent={pathData.pageContent}
            id={pathData.id}
            live
            liveDate={pathData.liveDate}
          />
        )}
        {pathData.path === 'paguemenosextrafarma' && (
          <PagueMenosLandingPageContent
            logoUrl={pathData.logoUrl}
            videoUrl={pathData.videoUrl}
            pageTitle={pathData.pageTitle}
            pageContent={pathData.pageContent}
            id={pathData.id}
            live
            liveDate={pathData.liveDate}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default LandingPageMgm;
